import { Stack } from "components/generic/Stacks";
import React from "react";
import styled from "styled-components";
import { palette } from "styles/theme";
import type { PageContext } from "templates/docs/integrations/EndpointMappingPage";
import { APIEndpointTabKey } from "types/types";
import { navigate } from "gatsby";
import { apiCategoryFromString, displayNameForAPICategory } from "../utils/services";
import DocsHelmet from "../shared-components/DocsHelmet";
import { PricingInfoCard } from "../shared-components/NoticeCards";
import { Badge } from "@merge-api/merge-javascript-shared";
import DeprecatedH1 from "components/deprecated/DeprecatedH1";
import DeprecatedH2 from "components/deprecated/DeprecatedH2";
import { IconContainer } from "templates/docs/integrations/IntegrationsOverviewPage";
import { StaticImage } from "gatsby-plugin-image";

type Props = Pick<PageContext, "categorySlug" | "integration"> & {
  selectedTab: string;
  showEndpointsTab: boolean;
  isInBeta: boolean;
};

const DIMENSION = 40; // px

/**
 * Rounded, 40px icon
 */
const Icon = styled.img`
  background: ${palette.white};
  border: 1px solid ${palette.border};
  border-radius: ${DIMENSION}px;
  width: ${DIMENSION}px;
  height: ${DIMENSION}px;
`;

const SansSerifDiv = styled.div`
  font-family: var(--font-family-sans-serif);
`;

const Title = styled(DeprecatedH1)`
  && {
    font-size: 32px;
  }
`;

const ExplainerHeader = styled(DeprecatedH2)`
  && {
    font-size: 24px;
    margin-bottom: 0.5em;
  }
`;

const Navbar = styled.div`
  display: flex;
  margin: 24px 0 24px 0;
  border-bottom: 1px solid var(--gray10);

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-bottom: 0.5px solid var(--gray20);
  }
`;

type NavbarItemProps = {
  isSelected: boolean;
};

const NavbarItem = styled.div<NavbarItemProps>`
  cursor: pointer;
  display: flex;
  border-bottom: ${({ isSelected }) => (isSelected ? "1px solid #075FF7" : "")}};
  

  & ~ & {
    margin: 0 0 0 26px;
  }
`;

const TabText = styled.div<NavbarItemProps>`
  color: ${({ isSelected }) => (isSelected ? "#075FF7" : "")};
  padding-bottom: 10px;
  font-weight: 600;
  position: sticky;
  top: 0;
  z-index: 1;
  background: ${palette.white};
  border-radius: 5px;
`;

const StyledStack = styled(Stack)`
  font-family: var(--font-family-sans-serif);
`;

const AlternativeSyncOptionAside = styled(PricingInfoCard)`
  margin-top: 22px;
  margin-bottom: 0px;
`;

const BadgeContainer = styled.div`
  align-items: center;
  width: ${DIMENSION}px;
  height: ${DIMENSION}px;
  display: flex;
`;

/**
 * Creates the header of a third party interaction page for the
 * EndpointMappingPage.
 */
const Header = ({ categorySlug, integration, selectedTab, showEndpointsTab, isInBeta }: Props) => {
  // Some integration names have "API" in the name and we don't want to repeat it
  const apiEndpointsText = integration.name.includes("API")
    ? `${integration.name} endpoints`
    : `${integration.name} API endpoints`;
  const commonModelSyncDescriptor =
    integration.categories.length === 1
      ? "Common Model Sync Frequencies"
      : `${displayNameForAPICategory(
          apiCategoryFromString(categorySlug),
        )} Common Model Sync Frequencies`;

  const image =
    integration.name == "Gusto" ? (
      <IconContainer>
        <StaticImage src="../../../assets/images/gusto_generic.png" alt="" />
      </IconContainer>
    ) : (
      <Icon src={integration?.square_image || ""} />
    );

  const syncFrequencyDescription =
    integration.slug == "quickbooks-desktop" ? (
      <div>
        Sync frequency for {integration.name} is controlled by a configuration file that users
        download and upload into their {integration.name} app. The default frequency is once per
        minute, separate from standard sync frequencies. To sync, the user's computer/server and web
        connector must be running.
      </div>
    ) : (
      <div>
        Merge requests data from {integration.name} at the intervals listed below based on your sync
        plan. Actual time to complete a sync can vary depending on the integration. For syncing
        outside of our normal sync intervals, use the{" "}
        <a href="/hris/force-resync/" target="_blank">
          Force Resync endpoint
        </a>{" "}
        or{" "}
        <a href="/basics/webhooks/third-party-webhooks" target="_blank">
          Automatic Webhooks
        </a>{" "}
        feature.
      </div>
    );

  const thirdPartyEndpointDescription =
    integration.slug == "quickbooks-desktop" ? (
      <div>
        Merge will access data by making queries to the Quickbooks Web Connector.{" "}
        <a
          href="https://developer.intuit.com/app/developer/qbdesktop/docs/api-reference/qbdesktop"
          target="_blank"
          rel="noreferrer"
        >
          Learn more about QBD queries.
        </a>
      </div>
    ) : (
      <div>
        Merge interacts with {apiEndpointsText} to access certain information as detailed below.
      </div>
    );

  return (
    <>
      <SansSerifDiv>
        <DocsHelmet title={`Merge Docs - ${integration.name} Endpoints`} />
        <StyledStack $isVertical>
          <Stack $gap={16}>
            {image}
            <Title className="mb-0">{integration.name}</Title>
            {isInBeta && (
              <BadgeContainer>
                <Badge color="blue" size="lg">
                  Beta
                </Badge>
              </BadgeContainer>
            )}
          </Stack>
          <Navbar>
            <NavbarItem
              key={APIEndpointTabKey.SYNC_FREQUENCIES}
              onClick={() => {
                navigate(
                  `/integrations/${categorySlug}/${integration.slug}/${APIEndpointTabKey.SYNC_FREQUENCIES}`,
                );
              }}
              isSelected={selectedTab === APIEndpointTabKey.SYNC_FREQUENCIES}
            >
              <TabText isSelected={selectedTab === APIEndpointTabKey.SYNC_FREQUENCIES}>
                Common Model Sync Frequencies
              </TabText>
            </NavbarItem>
            {showEndpointsTab && (
              <NavbarItem
                key={APIEndpointTabKey.PROVIDER_INTERACTIONS}
                onClick={() => {
                  navigate(
                    `/integrations/${categorySlug}/${integration.slug}/${APIEndpointTabKey.PROVIDER_INTERACTIONS}`,
                  );
                }}
                isSelected={selectedTab === APIEndpointTabKey.PROVIDER_INTERACTIONS}
              >
                <TabText isSelected={selectedTab === APIEndpointTabKey.PROVIDER_INTERACTIONS}>
                  Accessed third-party endpoints
                </TabText>
              </NavbarItem>
            )}
          </Navbar>
        </StyledStack>
        <Stack $isVertical>
          {selectedTab === APIEndpointTabKey.PROVIDER_INTERACTIONS && thirdPartyEndpointDescription}
          {selectedTab === APIEndpointTabKey.SYNC_FREQUENCIES && syncFrequencyDescription}
        </Stack>
      </SansSerifDiv>
    </>
  );
};

export default Header;
