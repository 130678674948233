import { Stack } from "components/generic/Stacks";
import React from "react";
import styled from "styled-components";
import { palette } from "styles/theme";
import { EndpointMapping } from "types/types";
import ThirdPartyInteractionEntry from "./ThirdPartyInteractionEntry";
import DeprecatedH3 from "components/deprecated/DeprecatedH3";

interface Props {
  endpoints: EndpointMapping["endpoints"];
  category: string;
}

const Container = styled(Stack)`
  margin-top: 40px;
  font-family: var(--font-family-sans-serif);
`;

const Title = styled(DeprecatedH3)`
  && {
    font-size: 20px;
    margin-bottom: 0;
  }
`;
const Border = styled.hr`
  width: 100%;
  border-top: 1px solid ${palette.border};

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-top: 0.5px solid var(--gray20);
  }
`;

/**
 * Shows all API endpoints for the section on the `EndpointMappingsPage`.
 * Header + the actual endpoints.
 */
const ApiEndpointsSection = ({ endpoints, category }: Props) => (
  <Container $isVertical>
    <Title>API Endpoints</Title>
    <Border />
    {endpoints.map((endpoint) => (
      <ThirdPartyInteractionEntry key={endpoint.path} {...endpoint} category={category} />
    ))}
  </Container>
);

export default ApiEndpointsSection;
