import { StartStack, Stack } from "components/generic/Stacks";
import React from "react";
import styled from "styled-components";
import { palette } from "styles/theme";
import { Endpoint } from "types/types";
import { HTTPMethodBadge } from "../shared-components/MergeBadges";
import InteractionAccordionCard from "./InteractionAccordionCard";

type Props = Endpoint;

// Last one has no border
const Section = styled(Stack)`
  padding: 16px 0;
  align-items: flex-start;
  justify-content: space-between;
  flex-basis: 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${palette.border};

    /* Retina */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      border-bottom: 0.5px solid var(--gray20);
    }
  }
  @media (max-width: 991.96px) {
    flex-direction: column;
    gap: 16px;
  }
`;

const HTTPMethodBadgeWithStyle = styled(HTTPMethodBadge)`
  font-size: 15px;
  word-break: normal;
`;

const PathStack = styled(StartStack)`
  margin-right: 32px;
  flex: 1;
  min-width: 120px;
  word-break: break-word;
`;

/**
 * Shows one entry for an endpoint and list of
 * common models on the right side.
 */
const ThirdPartyInteractionEntry = ({
  category,
  path,
  common_models: commonModels,
  method,
}: Props) => {
  const filteredCommonModels = commonModels.filter((commonModel) => {
    const [modelCategory] = commonModel.model_name.split(".");
    return modelCategory === category;
  });

  const hasMatchingCommonModel = filteredCommonModels.length > 0;

  if (hasMatchingCommonModel) {
    return (
      <Section>
        <PathStack>
          <HTTPMethodBadgeWithStyle method={method} />
          {path}
        </PathStack>
        <InteractionAccordionCard commonModels={filteredCommonModels} />
      </Section>
    );
  } else {
    return null;
  }
};

export default ThirdPartyInteractionEntry;
