import { Stack } from "components/generic/Stacks";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Integration } from "types/types";
import { Card, Table } from "react-bootstrap";
import { fetchWithoutAuth } from "api_client";
import LoadingRows, {
  BoldTableColumn,
  SyncFrequenciesTableHeader,
  TableColumn,
} from "./LoadingRows";

interface SpeedConfigSettings {
  SLOW: string;
  INTERMEDIATE: string;
  FAST: string;
  DAILY?: string;
}
interface SpeedConfig {
  [model: string]: SpeedConfigSettings;
}

interface Props {
  integration: Integration;
  category: string;
}

const Container = styled(Stack)`
  margin-top: 24px;
  font-family: var(--font-family-sans-serif);
`;

/**
 * Shows all API endpoints for the section on the `EndpointMappingsPage`.
 * Header + the actual endpoints.
 */
const CommonModelSyncFrequenciesSection = ({ integration, category }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [speedConfigs, setSpeedConfigs] = useState<SpeedConfig | undefined>(undefined);

  useEffect(() => {
    const fetchConfigs = () => {
      setIsLoading(true);
      fetchWithoutAuth({
        path: `/integrations/${integration.name}/common-model-sync-frequencies/${category}`,
        method: "GET",
        onResponse: (data: SpeedConfig) => {
          setSpeedConfigs(data);
          setIsLoading(false);
        },
        onError: () => {
          setIsLoading(false);
        },
      });
    };

    fetchConfigs();
  }, [category, integration.name]);

  if (isLoading) {
    return (
      <Container $isVertical>
        <LoadingRows />
      </Container>
    );
  }

  return (
    <Container $isVertical>
      <Card className="shadow-md">
        <Table responsive hover className="table-nowrap">
          <tbody className="table-borderless">
            {SyncFrequenciesTableHeader}
            {speedConfigs &&
              Object.entries(speedConfigs).map(([model, speedConfigSettings]) => (
                <tr key={model}>
                  <BoldTableColumn>{model}</BoldTableColumn>
                  <TableColumn>—</TableColumn>
                  <TableColumn>
                    {speedConfigSettings.DAILY ?? (speedConfigSettings.FAST ? "24 hours" : "—")}
                  </TableColumn>
                  <TableColumn>{speedConfigSettings.SLOW || "—"}</TableColumn>
                  <TableColumn>{speedConfigSettings.INTERMEDIATE || "—"}</TableColumn>
                  <TableColumn>
                    {speedConfigSettings.FAST === "1 minute"
                      ? "1-5 minutes"
                      : speedConfigSettings.FAST || "—"}
                  </TableColumn>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card>
    </Container>
  );
};

export default CommonModelSyncFrequenciesSection;
