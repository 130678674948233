import { INTEGRATION_SLUGS_WITHOUT_SYNC_FREQUENCY_OR_ENDPOINTS } from "components/docs/assets/constants";
import ApiEndpointsSection from "components/docs/integrations/ApiEndpointsSection";
import CommonModelSyncFrequenciesSection from "components/docs/integrations/CommonModelSyncFrequenciesSection";
import Header from "components/docs/integrations/Header";
import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { EndpointMapping, APIEndpointTabKey } from "types/types";

export type PageContext = EndpointMapping & {
  /**
   * As the integration can show for multiple categories, this just
   * determines which to render right now.
   */
  categorySlug: string;
  providedTab: string;
  isInBeta: boolean;
};

interface Props {
  pageContext: PageContext;
}

const PaddedRow = styled(Row)`
  && {
    @media (max-width: 767.96px) {
      padding: 40px 4px;
    }
    padding: 40px;
    margin: 0 auto;
    width: 100%;
    display: flex;
  }
`;

const PaddedCol = styled(Col)`
  && {
    @media (max-width: 767.96px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
`;

/**
 * Used by gatsby-node - creates pages from every EndpointMapping to show
 * which fields are possible to use on the page for this integration.
 * Appears as one of the pages under /docs/integrations/[category]/
 */
const EndpointMappingPage = ({
  pageContext: { integration, categorySlug, providedTab, endpoints, isInBeta },
}: Props) => (
  <PaddedRow>
    <PaddedCol>
      <Header
        categorySlug={categorySlug}
        integration={integration}
        selectedTab={providedTab}
        showEndpointsTab={endpoints?.length != 0}
        isInBeta={isInBeta}
      />
      {providedTab === APIEndpointTabKey.PROVIDER_INTERACTIONS &&
        !INTEGRATION_SLUGS_WITHOUT_SYNC_FREQUENCY_OR_ENDPOINTS.includes(integration.slug) && (
          <ApiEndpointsSection endpoints={endpoints} category={categorySlug} />
        )}
      {providedTab === APIEndpointTabKey.SYNC_FREQUENCIES &&
        !INTEGRATION_SLUGS_WITHOUT_SYNC_FREQUENCY_OR_ENDPOINTS.includes(integration.slug) && (
          <CommonModelSyncFrequenciesSection integration={integration} category={categorySlug} />
        )}
    </PaddedCol>
  </PaddedRow>
);

export default EndpointMappingPage;
