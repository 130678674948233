import {
  APICategory,
  getDefinitionForModelName,
  getTypes,
} from "@merge-api/merge-javascript-shared";
import type { OpenAPIV3 } from "openapi-types";
import React from "react";
import styled, { css } from "styled-components";
import { palette, spectrum } from "styles/theme";
import { MappedCommonModel } from "types/types";
import useCategorySchemas from "../hooks/useCategorySchemas";
import ParameterRow from "../ParameterRow";
import ExpandableSection, { Container } from "../sections/ExpandableSection";

interface Props {
  /**
   * Comes from the endpoint mappings for a specific integration
   */
  commonModels: Array<MappedCommonModel>;
}

const PADDING = css`
  padding: 8px 12px;
`;

const Header = styled.div`
  ${PADDING};
  font-weight: 600;
  position: sticky;
  top: 0;
  z-index: 1;
  background: ${palette.white};
  border-radius: 5px;
`;

const CODE_STYLE = css`
  font-family: var(--font-family-monospace);
  font-size: 13px;
  line-height: 24px;
  font-weight: 400;
  background: ${spectrum.gray0};
  padding: 1px 5px;
  border-radius: 3px;
`;

// Fixed width to keep everything the same size
const ContainerCard = styled.div`
  border: 1px solid ${palette.border};
  border-radius: 8px;
  width: 100%;
  @media (min-width: 991.96px) {
    overflow: hidden;
    max-height: 600px;
    overflow-y: auto;
    flex: 0 1 540px;
  }
  && {
    code {
      ${CODE_STYLE};
    }
  }
`;

const Row = styled(Container)`
  && {
    margin: 0;
    border-radius: 0;
    border-width: 1px 0 0 0;
    ${({ $isCollapsed }) =>
      $isCollapsed
        ? css`
            .deprecatedh1 {
              font-weight: 400;
            }
            padding: 0 12px;
          `
        : css`
            background: #fbfcfd;
            ${PADDING};
          `};
  }
`;

const Subtitle = styled.span`
  font-weight: 600;
  font-size: 12px;
  color: #8492a6;
`;

/**
 * Shows a card about the interactions Merge has with the endpoint
 */
const InteractionAccordionCard = ({ commonModels }: Props) => {
  const documents = useCategorySchemas();
  return (
    <ContainerCard>
      <Header>Merge interacts with this API endpoint to...</Header>
      {commonModels.map((commonModel) => {
        const [modelCategory, bareModelName] = commonModel.model_name.split(".");
        const document = documents[modelCategory as APICategory];
        const definition = getDefinitionForModelName(bareModelName, document);
        const properties = definition?.properties;
        return (
          <ExpandableSection
            key={commonModel.model_name}
            title={`Access ${bareModelName} information`}
            is3rdPartyEndpointPage={true}
            container={({ className, children, containerHeight, isCollapsed }) => (
              <Row
                $isCollapsed={isCollapsed}
                $containerHeight={containerHeight}
                className={className}
              >
                {children}
              </Row>
            )}
          >
            <Subtitle className="ml-3">{bareModelName} fields</Subtitle>
            {commonModel.mapped_fields.map((fieldName) => {
              const property = properties?.[fieldName] as OpenAPIV3.SchemaObject | undefined;
              const types = property ? getTypes(property, document).join(",") : "Unknown";
              return (
                <ParameterRow
                  rowClassName="pl-3 pr-3"
                  key={fieldName}
                  name={fieldName}
                  type={types}
                  required={false}
                />
              );
            })}
          </ExpandableSection>
        );
      })}
    </ContainerCard>
  );
};

export default InteractionAccordionCard;
