import React from "react";
import ContentLoader from "react-content-loader";
import { Card, Table } from "react-bootstrap";
import styled from "styled-components";
import Tooltip from "components/generic/Tooltip";
import { AlertTriangle } from "lucide-react";
import { spectrum } from "../../../styles/theme";

interface Props {
  width?: number;
  height?: number;
}

const SHARED_PROPS = {
  speed: 1.4,
  backgroundColor: spectrum.gray0,
  foregroundColor: spectrum.gray10,
  height: 20,
};

const TableHeaderRow = styled.tr`
  box-shadow: 0px 1px 0px #eaeef3;
  background-color: #ffffff;

  &:hover {
    background-color: #ffffff !important;
  }
`;

export const TableColumn = styled.td`
  padding-left: "30px";
`;

export const BoldTableColumn = styled(TableColumn)`
  font-weight: 600;
`;

const TooltipWithoutMaxWidth = styled(Tooltip)`
  & {
    .tooltip-inner {
      max-width: 100%;
    }
  }
`;

/**
 * Creates a loading rect/rect with circle with smart defaults
 */
const Loader = ({ width = 40, height = 20 }: Props) => (
  <ContentLoader
    {...SHARED_PROPS}
    width={width}
    viewBox={`0 ${(height - 20) / 4} ${width} ${height}`}
  >
    <rect x="0" rx="4" ry="4" width={width} height={height} />
  </ContentLoader>
);

export const SyncFrequenciesTableHeader = (
  <TableHeaderRow>
    <BoldTableColumn>Common Model</BoldTableColumn>
    <BoldTableColumn>Manual</BoldTableColumn>
    <BoldTableColumn>Daily</BoldTableColumn>
    <BoldTableColumn>
      Standard
      <TooltipWithoutMaxWidth
        fullWidth
        content="Standard sync frequency is only available to customers on Grow or Expand plans from before April 1, 2023."
      >
        <AlertTriangle size={14} color="#D9A800" className="ml-2 mb-1" />
      </TooltipWithoutMaxWidth>
    </BoldTableColumn>
    <BoldTableColumn>
      High
      <TooltipWithoutMaxWidth
        fullWidth
        content="High sync frequency is only available to customers on Grow or Expand plans from before April 1, 2023."
      >
        <AlertTriangle size={14} color="#D9A800" className="ml-2 mb-1" />
      </TooltipWithoutMaxWidth>
    </BoldTableColumn>
    <BoldTableColumn>
      Highest
      <TooltipWithoutMaxWidth
        fullWidth
        content="Actual sync frequency may differ due to third-party limitations and queueing."
      >
        <AlertTriangle size={14} color="#D9A800" className="ml-2 mb-1" />
      </TooltipWithoutMaxWidth>
    </BoldTableColumn>
  </TableHeaderRow>
);

/**
 * Creates a bunch of loading rows for the logs table
 */
const LoadingRows = () => (
  <Card className="w-100">
    <Table responsive hover className="table-nowrap">
      <tbody className="table-borderless">
        {SyncFrequenciesTableHeader}
        {Array.from({ length: 10 }).map((_, index) => (
          <tr>
            <TableColumn className="text-muted">
              <Loader />
            </TableColumn>
            <TableColumn className="text-muted">
              <Loader />
            </TableColumn>
            <TableColumn className="text-muted">
              <Loader />
            </TableColumn>
            <TableColumn className="text-muted">
              <Loader />
            </TableColumn>
            <TableColumn className="text-muted">
              <Loader />
            </TableColumn>
            <TableColumn className="text-muted">
              <Loader />
            </TableColumn>
          </tr>
        ))}
      </tbody>
    </Table>
  </Card>
);

export default LoadingRows;
